import { headerMenu } from '../../constant'
import { mapGetters, mapActions } from 'vuex'
import { truncate } from '@/utils'
import VueCookies from 'vue-cookies'
export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/Chevron'),
    G2Academy: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/G2Academy'),
    User: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/User'),
    SubMenuCard: () => import(/* webpackChunkName: "sub-menu-card" */ '@/components/header/SubMenuCard'),
    Exit: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/Exit'),
    Home: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/Home'),
    Bootcamp: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Bootcamp'),
    GlobeOutline: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/GlobeOutline')
  },
  props: {
    isDashboardPage: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    renderComponent: true,
    photo: '',
    loginItem: {
      student: [
        {
          text: 'Home',
          url: '/'
        },
        {
          text: 'Profile',
          url: '/user/profile'
        },
        {
          text: 'My Class',
          url: '/user/my-classes'
        },
        {
          text: 'Logout'
        }
      ],
      prakerja: [
        {
          text: 'Profile',
          url: '/profile'
        },
        {
          text: 'My Class',
          url: '/my-class'
        },
        {
          text: 'My Referral',
          url: '/my-referral-code'
        },
        {
          text: 'Logout'
        }
      ]
    },
    languangeOptions: [
      {
        label: 'English',
        code: 'en'
      },
      {
        label: 'Bahasa Indonesia',
        code: 'id'
      }
    ]
  }),
  filters: {
    truncate
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'userData']),
    ...mapGetters('common', ['isPhotoChanged']),
    languange() {
      return this.$i18n.locale
    },
    headerBarMenu() {
      return headerMenu.bar
    },
    headerNavigation() {
      return headerMenu.navigation
    },
    userRoles() {
      return this.userData.role_id
    },
    loginMenu() {
      return this.userRoles === 'STUDENT' ? this.loginItem.student : this.loginItem.prakerja
    },
    photoProfile() {
      if (this.photo === '') return localStorage.getItem('photo')
      return this.photo
    }
  },
  watch: {
    isLoggedIn(value) {
      if (value) {
        this.photo = localStorage.getItem('photo')
      }
    },
    isPhotoChanged(value) {
      this.photo = value
    }
  },
  methods: {
    ...mapActions('auth', ['logoutPrakerja', 'removeUserData']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    showMultilanguangeModal() {
      this.$emit('showMultilanguangeModal')
    },
    setLanguange(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('languange', lang)
    },
    doLogout() {
      VueCookies.remove('token')
      const cleanUserData = new Promise((resolve, reject) => {
        resolve(this.removeUserData())
      })
      cleanUserData.then(() => {
        this.$router.push({ name: 'Home' })
      })
    }
  }
}
